export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL';

export const CHANGE_STATUS_OF_TASK_REQUEST = 'CHANGE_STATUS_OF_TASK_REQUEST';
export const CHANGE_STATUS_OF_TASK_SUCCESS = 'CHANGE_STATUS_OF_TASK_SUCCESS';
export const CHANGE_STATUS_OF_TASK_FAIL = 'CHANGE_STATUS_OF_TASK_FAIL';

export const GET_TASK_DETAIL_REQUEST = 'GET_TASK_DETAIL_REQUEST';
export const GET_TASK_DETAIL_SUCCESS = 'GET_TASK_DETAIL_SUCCESS';
export const GET_TASK_DETAIL_FAIL = 'GET_TASK_DETAIL_FAIL';
export const GET_TASK_DETAIL_CLEAR = 'GET_TASK_DETAIL_CLEAR';

// Not status, not title, not description
export const UPDATE_TASK_MICRO_PROPS_REQUEST = 'UPDATE_TASK_MICRO_PROPS_REQUEST';
export const UPDATE_TASK_MICRO_PROPS_SUCCESS = 'UPDATE_TASK_MICRO_PROPS_SUCCESS';
export const UPDATE_TASK_MICRO_PROPS_FAIL = 'UPDATE_TASK_MICRO_PROPS_FAIL';
export const UPDATE_TASK_MICRO_PROPS_CLEAR = 'UPDATE_TASK_MICRO_PROPS_CLEAR';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';
export const DELETE_TASK_CLEAR = 'DELETE_TASK_CLEAR';
