/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

interface Props {

}

export const Main: React.FC<Props> = () => {
  return (
    <section className="">

    </section>
  );
};


